<template>
	<div>
		<global-menu />

		<f-default-header
			:title="$t('pages.settings.subtitle.bulkUpload')"
			:subtitle="
				$t('pages.settings.verticalActivityRel.subtitle.configRelation')
			" />

		<div
			v-html="$t('pages.settings.verticalActivityRel.help.bulkUpload')"
			class="status-alert"></div>

		<upload-form />
	</div>
</template>

<script>
import GlobalMenu from "../../../components/settings/verticalActivityRel/GlobalMenu.vue";
import UploadForm from "../../../components/settings/verticalActivityRel/UploadForm.vue";

export default {
	components: {
		GlobalMenu,
		UploadForm,
	},
};
</script>

<style lang="scss">
.status-alert {
	background-color: #cccccc;
	border-radius: 3px;
	margin-bottom: 24px;
	padding: 12px 24px;
	font-weight: normal;
}
</style>
