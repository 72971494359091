<template>
	<div>
		<v-stepper v-model="e6" vertical elevation="0">
			<!-- UPLOAD FILE -->
			<v-stepper-step :complete="e6 > 1" step="1">
				{{ $t("labels.selectFile") }}
				<!-- <small>Selecione um arquivo contendo todos os </small> -->
			</v-stepper-step>

			<v-stepper-content step="1">
				<div class="stepper-content">
					<v-file-input
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						@change="checkFile"
						:label="$t('labels.selectFile')" />
				</div>

				<v-btn
					color="primary"
					:disabled="this.$store.state.verticals.view.file === null"
					@click="processHeader">
					{{ $t("labels.btnFileProcess") }}
				</v-btn>
			</v-stepper-content>

			<!-- SELECT COLUMNS -->
			<v-stepper-step :complete="e6 > 2" step="2">
				{{ $t("pages.settings.verticalActivityRel.message.columnRelation") }}
			</v-stepper-step>

			<v-stepper-content step="2">
				<div class="stepper-content disabled-colors">
					<table>
						<tr>
							<td>{{ $t("labels.terms") }}:</td>
							<td>
								<v-select
									v-model="view.headers.terms"
									outlined
									dense
									clearable
									hide-details="auto"
									:items="items"
									:label="$t('labels.selectColumn')" />
							</td>
						</tr>
						<tr>
							<td>{{ $t("labels.keywords") }}:&nbsp;</td>
							<td>
								<v-select
									v-model="view.headers.words"
									outlined
									dense
									clearable
									hide-details="auto"
									:items="items"
									:label="$t('labels.selectColumn')" />
							</td>
						</tr>
						<tr>
							<td>{{ $t("labels.vertical") }}:</td>
							<td>
								<v-select
									v-model="view.headers.verticals"
									outlined
									dense
									clearable
									hide-details="auto"
									:items="items"
									:label="$t('labels.selectColumn')" />
							</td>
						</tr>
					</table>
				</div>

				<v-btn color="primary" @click="processData" elevation="0">
					{{ $t("labels.btnProcessData") }}
				</v-btn>
				&nbsp;
				<v-btn text @click="e6 = 1">{{ $t("labels.btnCancel") }}</v-btn>
			</v-stepper-content>
		</v-stepper>
	</div>
</template>

<script>
import GlobalMenu from "../../../components/settings/verticalActivityRel/GlobalMenu.vue";
import { SETTINGS_ROUTES } from "../../../router/settings";
import * as verticals from "./../../../services/verticals";

export default {
	components: {
		GlobalMenu,
	},
	data: () => ({
		fileHeaders: [],
		e6: 1,
	}),
	computed: {
		view() {
			return this.$store.state.verticals.view;
		},
		items() {
			return this.getItems();
		},
	},
	methods: {
		getItems() {
			var items = [];
			var unavailables = [];

			Object.values(this.view.headers).forEach((item) => {
				if (item) {
					unavailables.push(item);
				}
			});

			this.fileHeaders.forEach((item) => {
				var disabled = false;
				if (unavailables.includes(item)) {
					disabled = true;
				}

				items.push({
					text: item,
					value: item,
					disabled,
				});
			});

			return items;
		},
		// alow() {
		//   aloha();
		//   // readFileHeaders();
		// },
		async checkFile(file) {
			// console.log(file);

			// const file = e.target.files[0];
			this.$store.state.verticals.view.file = file;

			// this.$store.dispatch("system/loading", "data");
			// @TODO
			// const result = await this.$store.dispatch("var/checkHeadersConfig");
			// this.$store.dispatch("system/loading", "done");
		},
		async processHeader() {
			const result = await verticals.readFileHeaders();
			this.e6 = 2;
			this.fileHeaders = result;
		},
		async processData() {
			await verticals.processData();
			this.$store.state.verticals.view.file = null;

			this.$store.commit("snackbar/show", {
				content: "O arquivo está sendo processado.",
			});

			this.$router.push({
				name: SETTINGS_ROUTES.VERTICALS_REL_INDEX,
				query: {
					upload: true,
				},
			});
		},
	},
};
</script>

<style lang="scss">
.stepper-content {
	border-radius: 3px;
	border: 1px solid #e0e0e0;
	margin-bottom: 24px;
	padding: 12px;
}

.disabled-colors {
	.theme--light.v-select .v-select__selection--disabled {
		color: rgba(0, 0, 0, 0.7) !important;
	}
	.v-select {
		margin-bottom: 12px !important;
	}
}
</style>
